<template>
  <page-main
    title="Technical Skills"
    nextText="Descriptors"
    buttonTabIndex="3"
    eventName="nav"
    @nav="nav('/Adjectives')"
    @save="save()"
  >
    <template v-slot:aside>
      What technical skills do you have?
      <ul>
        <li>
          Technical skills, also known as hard skills, are typically connected
          with knowledge of a system, a program, trade, or an academic subject.
        </li>
        <li>
          Use the dropdown box to select relevant hard skills. If you want to
          inclue something that is not in the dropdown, then you can type it in
          - when you are finished typing in the skill, hit the Enter/Next button
          on your device to add.
        </li>
        <li>
          Newly selected skills will default to the central proficiency column -
          you can either drag and drop these or else use the arrows to indicate
          your proficiency with each column.
        </li>
      </ul>
    </template>
    <template v-slot:nav>
      <v-system-bar color="accent" height="5" class="mt-4"></v-system-bar>
      <modal cardTitle="Example" ctaText="Need an example?">
        <ul class="font-italic">
          <li class="mb-4">
            <span class="font-weight-bold">Example #1:</span>
            <ul>
              <li>
                <span class="font-weight-bold">Advanced:</span>
                Excel; PowerPoint; Scenario Analysis
              </li>
              <li>
                <span class="font-weight-bold">Familiar:</span>
                Computer Aided Design (CAD); Financial Planning & Analysis
                (FP&A)
              </li>
              <li>
                <span class="font-weight-bold">Basic:</span>
                C++; Python
              </li>
            </ul>
          </li>
          <li class="mb-4">
            <span class="font-weight-bold">Example #2:</span>
            <ul>
              <li>
                <span class="font-weight-bold">Expert:</span>
                Excel; PowerPoint; Scenario Analysis
              </li>
              <li>
                <span class="font-weight-bold">Functional:</span>
                Computer Aided Design (CAD); Financial Planning & Analysis
                (FP&A)
              </li>
              <li>
                <span class="font-weight-bold">Basic:</span>
                C++; Python
              </li>
            </ul>
          </li>
        </ul>
      </modal>
      <modal cardTitle="Tips" ctaText="Need a few tips?" :accentButton="true">
        <r-tip-container :tips="tips"></r-tip-container>
      </modal>
    </template>
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-multi-select-levels
            label="Type here to add or use drop down to select (one at a time)"
            titleText="Select or Add Skills:"
            :optionsList="baseHardSkills"
            :selected.sync="hardSkills"
            :categoryLabels.sync="hardSkillLabels"
            tabindex="1"
            autofocus
          ></r-multi-select-levels>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import * as Display from "../utility/display";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";

// @ is an alias to /src
export default {
  name: "HardSkills",
  data: () => ({}),
  methods: {
    save() {
      this.findDisplayValues();
      this.saveHardSkills();
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    ...mapActions("hardSkill", ["saveHardSkills"]),
    findDisplayValues() {
      let hardDisplay = Display.BuildCommaString("", this.hardSkills);

      this.hardSkillsDisplay = hardDisplay;

      return [hardDisplay];
    },
  },
  mounted() {},
  computed: {
    ...mapFields("hardSkill", [
      "hardSkills",
      "hardSkillsDisplay",
      "hardSkillLabels",
      "baseHardSkills",
    ]),
    displayValue: function () {
      return this.findDisplayValues();
    },
    tips() {
      return [
        "We recommend using the default labels “Advanced”, “Familiar” and “Basic,” however, this interactive experience is fully customizable – you can change the proficiency labels to better fit your resume’s needs.",
        "Select or type one skill at a time - that way you can be more flexible with moving around your skills.",
        "Try to order your skills from strongest to weakest.",
        "Do not exaggerate or falsify your proficiency! This can come back to haunt you during the interview and/or on the job.",
      ];
    },
  },
};
</script>
